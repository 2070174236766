import {
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  Skeleton,
  Stack,
} from '@chakra-ui/react';
import { PhoneIcon } from '@heroicons/react/24/solid';
import { createProfile } from 'api/createProfile';
import { deleteProfile } from 'api/deleteProfile';
import { ConnectionPlatform } from 'api/type';
import { IdContext } from 'App';
import Text from 'components/text';
import { useContext, useState } from 'react';
import { useMutation } from 'react-query';

interface BunjangProps {
  loading: boolean;
  connected: boolean;
  onSubmit: () => void;
}

const Bunjang: React.FC<BunjangProps> = ({ loading, connected, onSubmit }) => {
  const { value } = useContext(IdContext);

  const [toggle, setToggle] = useState(false);
  const [realName, setRealName] = useState('');
  const [birthday, setBirthday] = useState('');
  const [genderNumber, setGenderNumber] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneVendor, setPhoneVendor] = useState<
    'SKT' | 'KT' | 'LGU' | 'SKM' | 'KTM' | 'LGM'
  >('SKT');

  const onChangePhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const regex = /^[0-9\b]{0,11}$/;
    if (e.target.value === '' || regex.test(e.target.value)) {
      setPhoneNumber(e.target.value);
    }
  };

  const onChangeBirthday = (e: React.ChangeEvent<HTMLInputElement>) => {
    const regex = /^[0-9\b]{0,6}$/;
    if (e.target.value === '' || regex.test(e.target.value)) {
      setBirthday(e.target.value);
    }
  };

  const onChangeGenderNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const regex = /^[1-4]$/;
    if (e.target.value === '' || regex.test(e.target.value)) {
      setGenderNumber(e.target.value);
    }
  };

  const mutateCreateProfile = useMutation(async () => {
    const gn: number = parseInt(genderNumber);

    const response = await createProfile({
      serviceKey: value,
      bunjang: {
        realName,
        birthday,
        genderNumber: gn,
        phoneNumber,
        phoneVendor,
      },
    });

    if (!response.error) {
      onSubmit();
      setToggle(false);
      return response.response;
    }
  });

  const mutateDeleteProfile = useMutation(async () => {
    const response = await deleteProfile({
      serviceKey: value,
      platform: ConnectionPlatform.Bunjang,
    });

    if (!response.error) {
      onSubmit();
      setToggle(false);
      return response.response;
    }
  });

  return (
    <div className="w-full max-w-[350px] py-4 px-6 ring-1 ring-inset ring-gray-300 rounded-md transition-all duration-300">
      <div className="flex items-center justify-between">
        <div className="flex flex-1 items-center">
          <img
            src={require('../../asset/logo/bunjang.png')}
            alt="bunjang"
            className="h-7"
          />
          <div className="w-2" />
          <div className="flex flex-col">
            {loading ? (
              <Skeleton width="100px" height="28px" />
            ) : (
              <Text size="md">번개장터</Text>
            )}
          </div>
        </div>
        <div className="flex">
          {loading ? (
            <Skeleton width="60px" height="28px" />
          ) : (
            !connected && (
              <Button
                size="sm"
                isLoading={loading}
                isDisabled={loading || connected}
                onClick={() => setToggle(!toggle)}
              >
                {connected ? '연결됨' : '연결하기'}
              </Button>
            )
          )}
          {connected && (
            <>
              <div className="w-2" />
              <Button
                size="sm"
                isLoading={loading}
                isDisabled={loading || !connected}
                variant="outline"
                color="text-stone-600"
                onClick={() => {
                  mutateDeleteProfile.mutate();
                }}
              >
                삭제
              </Button>
            </>
          )}
        </div>
      </div>
      <div
        className={`overflow-hidden transition-all duration-300 ${toggle ? 'max-h-[500px]' : 'max-h-0'}`}
      >
        {toggle && (
          <div className="mt-4 w-full flex flex-col space-y-4">
            <Stack spacing={3}>
              <Input
                placeholder="이름"
                value={realName}
                onChange={(e) => setRealName(e.target.value)}
              />
              <Input
                placeholder="생년월일 (YYMMDD)"
                value={birthday}
                onChange={onChangeBirthday}
              />
              <Input
                placeholder="주민번호 뒷자리 첫번째"
                type="number"
                value={genderNumber}
                onChange={onChangeGenderNumber}
                maxLength={1}
              />
              <InputGroup>
                <InputLeftElement>
                  <PhoneIcon width={20} color="gray" />
                </InputLeftElement>
                <Input
                  type="tel"
                  placeholder="전화번호"
                  value={phoneNumber}
                  onChange={onChangePhoneNumber}
                />
              </InputGroup>
              <select
                value={phoneVendor}
                onChange={(e) =>
                  setPhoneVendor(
                    e.target.value as
                      | 'SKT'
                      | 'KT'
                      | 'LGU'
                      | 'SKM'
                      | 'KTM'
                      | 'LGM',
                  )
                }
                className="border p-2 rounded-md w-full"
              >
                <option value="SKT">SKT</option>
                <option value="KT">KT</option>
                <option value="LGU">LGU</option>
                <option value="SKM">SKM</option>
                <option value="KTM">KTM</option>
                <option value="LGM">LGM</option>
              </select>
            </Stack>

            <div className="flex justify-end">
              <Button
                size="sm"
                isLoading={mutateCreateProfile.isLoading}
                onClick={() => {
                  mutateCreateProfile.mutate();
                }}
              >
                제출
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Bunjang;
