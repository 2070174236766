import { Box, useToast } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';

import Text from 'components/text';
import useQueueScraping from 'hook/useQuueScraping';
import { sleep } from 'utils';
import { ConnectionPlatform } from 'api/type';

interface InstagramProps {
  onClose: () => void;
  step: number;
  setStep: (step: number) => void;
  selectedProducts: string[];
}

const Instagram: React.FC<InstagramProps> = ({
  onClose,
  step,
  selectedProducts,
}) => {
  const {
    initialized,
    hasOngoing,
    percent,
    trigger,
    waitForOngoingToComplete,
  } = useQueueScraping(ConnectionPlatform.Instagram);

  const toast = useToast();
  const [showModal, setShowModal] = useState(true);

  const toastIdRef = useRef<any>();
  const percentRef = useRef(percent);

  const upload = async () => {
    if (hasOngoing) {
      toast({
        description:
          '이미 진행중인 업로드가 있어요. 잠시 후에 다시 시도해주세요!',
        status: 'error',
      });

      onClose();
      return null;
    }

    if (step === 2) {
      setShowModal(false);

      trigger({
        params: {
          productIds: selectedProducts,
        },
      });

      toastIdRef.current = toast({
        title: `진행률 0%`,
        description: (
          <Text>상품을 업로드하는 중이에요. 잠시만 기다려주세요!</Text>
        ),
        duration: null,
        status: 'loading',
        isClosable: false,
      });

      const intervalId = setInterval(() => {
        toast.update(toastIdRef.current, {
          title: `진행률 ${Math.round(percentRef.current * 100)}%`,
          description: (
            <Box>
              <Text>상품을 업로드하는 중이에요. 잠시만 기다려주세요!</Text>
            </Box>
          ),
          status: 'loading',
          duration: null,
        });
      }, 1000);

      await sleep(1000);

      try {
        const result = await waitForOngoingToComplete();

        if (result) {
          throw new Error(result.toString());
        }

        clearInterval(intervalId);
        onClose();
        toast.update(toastIdRef.current, {
          description: (
            <Box>
              <Text>업로드에 성공했어요!</Text>
            </Box>
          ),
          status: 'success',
          duration: 3000,
        });
      } catch (error) {
        clearInterval(intervalId);
        onClose();
        toast.update(toastIdRef.current, {
          description: (
            <Box>
              <Text>
                업로드에 실패했어요. 인스타그램 아이디와 비밀번호를
                확인해주세요!
              </Text>
            </Box>
          ),
          status: 'error',
          duration: 3000,
        });
      }
    }
  };

  useEffect(() => {
    if (step === 2 && initialized) {
      upload();
    }
  }, [step, initialized]);

  useEffect(() => {
    percentRef.current = percent;
  }, [percent]);

  if (!showModal) {
    return null;
  }

  if (!initialized) {
    return null;
  }

  return <></>;
};

export default Instagram;
