import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom/';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeProvider } from '@material-tailwind/react';
import { RecoilRoot } from 'recoil';
import { Amplify } from 'aws-amplify';
import { AmplifyConfig } from 'awsConfig';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const queryClient = new QueryClient();

Amplify.configure(AmplifyConfig);

root.render(
  <ThemeProvider>
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </RecoilRoot>
    </QueryClientProvider>
  </ThemeProvider>,
);
