export type BunjangConnectionFirstParams = {
  realName: string;
  birthday: string;
  genderNumber: number; // 주민번호 뒤 1자리
  phoneNumber: string;
  phoneVendor: 'SKT' | 'KT' | 'LGU' | 'SKM' | 'KTM' | 'LGM';
};

export type CollectiveConnectionFirstParams = {
  phoneNumber: string;
};

export type FruitFamilySourceConnectionFirstParams = {
  username: string;
};

export interface InstagramProfile {
  id: string;
  password: string;
}

export enum ConnectionPlatform {
  Bunjang = 'bunjang',
  Collective = 'collective',
  FruiteFamily = 'fruit_family',
  Instagram = 'instagram',
}
