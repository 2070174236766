import {
  Badge,
  Button,
  Card,
  CardBody,
  IconButton,
  useToast,
} from '@chakra-ui/react';
import { Carousel } from '@material-tailwind/react';
import { getConnections } from 'api/getConnection';
import { getProducts } from 'api/getProducts';
import { getScrapingStatus } from 'api/getScrapingStatus';
import { triggerConnection } from 'api/triggerConnection';
import { ConnectionPlatform } from 'api/type';
import PageLayout from 'components/layout/page';
import Text from 'components/text';
import UploadModal from 'components/uploadModal';
import { Product, ProductStatus } from 'const/type';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useMutation, useQuery } from 'react-query';
import { animated, useSpring } from 'react-spring';
import { priceFormat } from 'utils';

function List() {
  const [selectedProducts, setSelectedProducts] = useState<number[]>([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [scrollDetection, setScrollDetection] = useState(false);

  const toast = useToast();

  const triggerRefreshFlag = useRef(false);
  const [hasOngoing, setHasOngoing] = useState(false);

  const itemsPerPage = 10;

  const buttonSpring = useSpring({
    transform:
      selectedProducts.length !== 0 ? 'translateY(0vh)' : 'translateY(100vh)',
    config: { tension: 170, friction: 26, clamp: true },
  });

  const queryData = useMutation(async () => {
    const response = await getProducts();

    return response.response;
  });

  const products = useMemo<Product[]>(() => {
    if (!queryData.data) {
      return [];
    }

    return queryData.data.products || [];
  }, [queryData.data]);

  const statuses = useMemo<ProductStatus[]>(() => {
    return queryData.data?.productStatuses || [];
  }, [queryData.data]);

  const displayedProducts = useMemo(() => {
    return products.slice(0, page * itemsPerPage);
  }, [products, page]);

  const queryConnections = useMutation(async () => {
    const response = await getConnections();

    return response.response;
  });

  const queryScrapingStatus = useQuery(
    'scraping_status_fruits',
    async () => {
      const response = await getScrapingStatus(ConnectionPlatform.FruiteFamily);

      return response.response;
    },
    {
      refetchInterval: () => (hasOngoing ? 10000 : false),
    },
  );

  const mutateTriggerConnection = useMutation(async () => {
    const response = await triggerConnection(ConnectionPlatform.FruiteFamily);

    return response.response;
  });

  const onClickRefresh = async () => {
    const currentState = await queryScrapingStatus.refetch();
    const isOngoing = currentState.data.ongoing;

    if (!isOngoing) {
      toast({
        description:
          '후르츠패밀리에서 상품을 업데이트하고 있어요. 시간이 조금 걸릴 수 있으니 잠시만 기다려 주세요!',
        status: 'warning',
        duration: 3000,
      });
      await mutateTriggerConnection.mutateAsync();
      queryScrapingStatus.refetch();
    }
  };

  const getSelectedProduct = () => {
    const { data } = queryData;

    if (!data) {
      return [];
    }

    const { products } = data;
    return selectedProducts.map((index) => products[index].sk.split('#')[2]);
  };

  const onClickRealse = () => {
    setSelectedProducts([]);
  };

  const handleLongPress = (index: number) => {
    setSelectedProducts((prevSelectedProducts) =>
      prevSelectedProducts.includes(index)
        ? prevSelectedProducts.filter((item) => item !== index)
        : prevSelectedProducts.length < 10
          ? [...prevSelectedProducts, index]
          : prevSelectedProducts,
    );
  };

  useEffect(() => {
    queryData.mutate();
    setTimeout(() => {
      setScrollDetection(true);
    }, 1000);
  }, []);

  useEffect(() => {
    queryConnections.mutate();
  }, []);

  useEffect(() => {
    if (queryScrapingStatus.data) {
      if (queryScrapingStatus.data.ongoing) {
        setHasOngoing(true);
        triggerRefreshFlag.current = true;
      } else if (triggerRefreshFlag.current) {
        queryData.mutate();
        setHasOngoing(false);
        triggerRefreshFlag.current = false;
      }
    }
  }, [queryScrapingStatus.data]);

  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: false,
  });

  useEffect(() => {
    if (inView && hasMore && products.length !== 0) {
      setPage((prevPage) => {
        if (prevPage * itemsPerPage >= products.length) {
          setHasMore(false);
        }
        return prevPage + 1;
      });
    }
  }, [inView, hasMore, products.length]);

  return (
    <PageLayout className="overflow-x-hidden">
      <div className={`flex w-full h-full flex-col`}>
        <div className="flex justify-end">
          <Button
            isLoading={
              queryScrapingStatus.isLoading ||
              (queryScrapingStatus.data && queryScrapingStatus.data.ongoing)
            }
            isDisabled={
              !queryConnections.data ||
              queryConnections.data.fruit_family === false
            }
            onClick={onClickRefresh}
            variant={'outline'}
          >
            상품 업데이트
          </Button>

          <div className="w-2" />

          <Button
            isLoading={queryData.isLoading}
            onClick={() => {
              toast.promise(queryData.mutateAsync(), {
                success: {
                  description: '등록된 상품 목록을 가져왔어요!',
                },
                error: {
                  description: '등록된 상품 목록을 가져오는데 실패했어요.',
                },
                loading: {
                  description: '등록된 상품 목록을 가져오는 중이에요!',
                },
              });
            }}
          >
            새로고침
          </Button>
        </div>

        <div className="flex-1 grid grid-col-1 sm:grid-cols-2 gap-2 mt-4 overflow-x-hidden">
          {displayedProducts.map((product, index) => (
            <div
              className="overflow-hidden"
              key={`product-${product.pk}-${index}`}
            >
              <ItemCard
                product={product}
                status={statuses.find(
                  (status) =>
                    status.sk.split('#')[2] === product.sk.split('#')[2],
                )}
                index={index}
                isSelected={selectedProducts.includes(index)}
                onLongPress={handleLongPress}
              />
            </div>
          ))}
        </div>
        {scrollDetection && hasMore ? (
          <div ref={ref} className="h-10" />
        ) : (
          <div className="h-5" />
        )}
      </div>

      <animated.div
        style={buttonSpring}
        className="fixed bottom-0 right-10 transform mb-4 h-fit z-[1000]"
      >
        <UploadModal
          selectedProducts={getSelectedProduct()}
          connectedPlatforms={queryConnections.data}
          onClickRealse={onClickRealse}
        />
      </animated.div>
    </PageLayout>
  );
}

interface ItemCardProps {
  product: Product;
  index: number;
  status: ProductStatus | undefined;
  isSelected: boolean;
  onLongPress: (index: number) => void;
}

const ItemCard: React.FC<ItemCardProps> = ({
  product,
  index,
  status,
  isSelected,
  onLongPress,
}) => {
  console.log(product, status);

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    onLongPress(index);
  };

  return (
    <Card
      variant={'outline'}
      rounded="xl"
      onClick={handleClick}
      className="select-none h-full"
    >
      {isSelected && (
        <div className="absolute inset-0 bg-black bg-opacity-50 z-[100] flex items-center justify-center rounded-xl">
          <Text size="2xl">✅</Text>
        </div>
      )}
      <Carousel
        className="rounded-xl max-h-[274px] overflow-hidden relative"
        navigation={({ setActiveIndex, activeIndex, length }) => (
          <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2  overflow-hidden">
            {new Array(length).fill('').map((_, i) => (
              <span
                key={i}
                className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${
                  activeIndex === i ? 'w-8 bg-white' : 'w-4 bg-white/50'
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  setActiveIndex(i);
                }}
              />
            ))}
          </div>
        )}
        prevArrow={({ handlePrev }) => (
          <IconButton
            aria-label="prev"
            onClick={(e) => {
              e.stopPropagation();
              handlePrev();
            }}
            variant="text"
            color="white"
            size="lg"
            className="!absolute top-2/4 left-4 -translate-y-2/4 hover:bg-white/20 rounded-full overflow-hidden"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 19.5 8.25 12l7.5-7.5"
              />
            </svg>
          </IconButton>
        )}
        nextArrow={({ handleNext }) => (
          <IconButton
            aria-label="next"
            onClick={(e) => {
              e.stopPropagation();
              handleNext();
            }}
            variant="text"
            color="white"
            size="lg"
            className="!absolute top-2/4 !right-4 -translate-y-2/4 hover:bg-white/20 rounded-full  overflow-hidden"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </IconButton>
        )}
      >
        {product.images.map((image, index) => (
          <img
            key={`product-${product.pk}-image-${index}`}
            src={image}
            alt="product image"
            className="h-full w-full object-cover max-w-full"
          />
        ))}
      </Carousel>
      <CardBody className="flex flex-col space-y-2">
        <div className="flex items-center gap-2">
          <Badge
            colorScheme={product.status === 'selling' ? 'green' : 'red'}
            className="w-fit"
          >
            {product.status === 'selling' ? '판매중' : '판매중지'}
          </Badge>

          <div className="flex items-center gap-2">
            {status?.upload.fruit_family ? (
              <div className="flex items-center">
                <img
                  src={require('../../asset/logo/fruitsfamilyLogo.png')}
                  alt="bunjang"
                  className="size-6"
                />
              </div>
            ) : null}

            {status?.upload.bunjang ? (
              <div className="flex items-center">
                <img
                  src={require('../../asset/logo/bunjang.png')}
                  alt="bunjang"
                  className="size-6"
                />
              </div>
            ) : null}

            {status?.upload.collective ? (
              <div className="flex items-center">
                <img
                  src={require('../../asset/logo/collective.png')}
                  alt="auction"
                  className="size-6"
                />
              </div>
            ) : null}
          </div>
        </div>

        <div className="text-lg font-bold text-gray-800">{product.title}</div>
        <div className="text-sm text-gray-600">{product.brand} </div>
        <div className="flex items-center gap-2">
          <div className="text-sm text-gray-600">
            {product.category} · {priceFormat(product.price)}
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default List;
