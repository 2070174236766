import { useRef, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Card,
} from '@chakra-ui/react';
import Text from 'components/text';
import Collective from './collective';
import Instagram from './instagram';
import Bunjang from './bunjang';
import { ConnectionPlatform } from 'api/type';

interface UploadModalProps {
  selectedProducts: string[];
  connectedPlatforms: {
    collective: boolean;
    bunjang: boolean;
    instagram: boolean;
  };
  onClickRealse: () => void;
}

const UploadModal: React.FC<UploadModalProps> = ({
  selectedProducts,
  connectedPlatforms,
  onClickRealse,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [step, setStep] = useState(1);
  const [platform, setPlatform] = useState<ConnectionPlatform | null>(null);

  const onClickPlatform = async (value: ConnectionPlatform) => {
    setPlatform(value);
    setStep(2);
  };

  const handleReset = () => {
    setStep(1);
    setPlatform(null);
  };

  const handleClose = () => {
    setStep(1);
    setPlatform(null);
    onClose();
  };

  return (
    <>
      <div className="flex gap-2">
        <Button onClick={onClickRealse} zIndex={10}>
          선택상품 해제
        </Button>
        <Button colorScheme="blue" onClick={onOpen} zIndex={10}>
          업로드
        </Button>
      </div>

      <Modal
        isOpen={isOpen}
        onClose={handleClose}
        isCentered
        scrollBehavior="outside"
        motionPreset="slideInBottom"
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <ModalOverlay height="100vh" zIndex={1000} />
        <ModalContent
          position="absolute"
          bottom="0px"
          mb="0"
          borderRadius="1rem 1rem 0px 0px"
          maxW="lg"
        >
          {platform === null && (
            <>
              <ModalHeader>업로드할 플랫폼을 선택해주세요.</ModalHeader>
              <ModalCloseButton onClick={handleReset} />
              <ModalBody>
                <div>
                  <Text>연동된 플랫폼</Text>

                  {connectedPlatforms && connectedPlatforms.collective && (
                    <>
                      <div className="h-4" />
                      <div className="flex items-center justify-between">
                        <Card
                          className="w-full"
                          variant={'outline'}
                          onClick={() =>
                            onClickPlatform(ConnectionPlatform.Collective)
                          }
                        >
                          <div className="flex w-full px-4 py-3">
                            <img
                              src={require('../../asset/logo/collective.png')}
                              alt="fruits"
                              className="h-7"
                            />
                            <div className="w-2" />
                            <div className="flex flex-col">
                              <Text size="md">콜랙티브</Text>
                            </div>
                          </div>
                        </Card>
                      </div>
                    </>
                  )}

                  {connectedPlatforms && connectedPlatforms.instagram && (
                    <>
                      <div className="h-4" />

                      <div className="flex items-center justify-between">
                        <Card
                          className="w-full"
                          variant={'outline'}
                          onClick={() =>
                            onClickPlatform(ConnectionPlatform.Instagram)
                          }
                        >
                          <div className="flex w-full px-4 py-3">
                            <img
                              src={require('../../asset/logo/instagram.png')}
                              alt="fruits"
                              className="h-7"
                            />
                            <div className="w-2" />
                            <div className="flex flex-col">
                              <Text size="md">인스타그램</Text>
                            </div>
                          </div>
                        </Card>
                      </div>
                    </>
                  )}

                  {connectedPlatforms && connectedPlatforms.bunjang && (
                    <>
                      <div className="h-4" />
                      <div className="flex items-center justify-between">
                        <Card
                          className="w-full"
                          variant={'outline'}
                          onClick={() =>
                            onClickPlatform(ConnectionPlatform.Bunjang)
                          }
                        >
                          <div className="flex w-full px-4 py-3">
                            <img
                              src={require('../../asset/logo/bunjang.png')}
                              alt="fruits"
                              className="h-7"
                            />
                            <div className="w-2" />
                            <div className="flex flex-col">
                              <Text size="md">번개장터</Text>
                            </div>
                          </div>
                        </Card>
                      </div>
                    </>
                  )}

                  {connectedPlatforms &&
                    !(
                      connectedPlatforms.bunjang ||
                      connectedPlatforms.collective ||
                      connectedPlatforms.instagram
                    ) && (
                      <>
                        <div className="h-4" />

                        <div className="flex items-center justify-between">
                          <div className="w-full">
                            <Text size="md" bold wrap textCenter>
                              연동된 플랫폼이 없어요 😢
                              <br />
                              먼저 플랫폼을 연동해주세요.
                            </Text>
                          </div>
                        </div>
                      </>
                    )}

                  <div className="h-4" />
                </div>
              </ModalBody>
            </>
          )}

          {platform === ConnectionPlatform.Collective && (
            <Collective
              onClose={handleClose}
              step={step}
              setStep={setStep}
              selectedProducts={selectedProducts}
            />
          )}

          {platform === ConnectionPlatform.Instagram && (
            <Instagram
              onClose={handleClose}
              step={step}
              setStep={setStep}
              selectedProducts={selectedProducts}
            />
          )}

          {platform === ConnectionPlatform.Bunjang && (
            <Bunjang
              onClose={handleClose}
              step={step}
              setStep={setStep}
              selectedProducts={selectedProducts}
            />
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default UploadModal;
