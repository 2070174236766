import { Route, Routes, useLocation } from 'react-router-dom';

import List from 'pages/list';
import Header from 'components/header';
import Profile from 'pages/profile';
import { createContext, useEffect, useState } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import Landing from 'pages/landing';
import { useRecoilState } from 'recoil';
import { userState } from 'globalState';
import Cognito from 'libs/cognito';

export const IdContext = createContext({
  value: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setValue: (value: string) => {},
});

function App() {
  const location = useLocation();
  const { pathname } = location;

  const [cognitoUser, setCognitoUser] = useRecoilState(userState.cognitoUser);
  const [id, setId] = useState<string>('');

  useEffect(() => {
    const updateUser = async () => {
      try {
        const user = await Cognito.getCurrentUser();
        setCognitoUser(user);
      } catch {
        setCognitoUser(null);
      }
    };

    updateUser();
  }, []);

  return (
    <ChakraProvider>
      <IdContext.Provider
        value={{
          value: id,
          setValue: setId,
        }}
      >
        <div className="flex flex-col items-center">
          {!cognitoUser && (
            <div className="relative flex-1 w-full">
              <Header isLanding={true} />
              <Routes>
                <Route path="/" element={<Landing />} />
              </Routes>
            </div>
          )}

          {cognitoUser && (
            <div className="flex-1 max-w-[640px] w-full">
              <Header isLanding={false} />
              <div className="relative flex-1 w-full">
                <Routes>
                  <Route path="/" element={<List />} />
                  <Route path="/list" element={<List />} />
                  <Route path="/profile" element={<Profile />} />
                  <Route path="*" element={<h1>Not Found</h1>} />
                </Routes>
              </div>
            </div>
          )}
        </div>
      </IdContext.Provider>
    </ChakraProvider>
  );
}

export default App;
