export function classNames(...classes: (string | boolean | undefined)[]) {
  return classes.filter(Boolean).join(' ');
}

export function isUUID4(uuid: string) {
  const regex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return regex.test(uuid);
}

export function priceFormat(price: number | string) {
  const numericPrice = typeof price === 'string' ? parseFloat(price) : price;
  const integerPrice = Math.floor(numericPrice);
  const formattedPrice = integerPrice
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return `₩${formattedPrice}`;
}

export async function sleep(timeout: number) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(true), timeout);
  });
}
