import { ConnectionPlatform } from './type';
import { apiPost } from 'libs/api';

type DeleteProfileRequest = {
  serviceKey: string;
  platform: ConnectionPlatform;
};

export const deleteProfile = async (request: DeleteProfileRequest) => {
  const result = await apiPost('API', '/connections/delete', request, {
    auth: true,
  });

  return result;
};
