import { Box, CircularProgress, Input, useToast } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import {
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
} from '@chakra-ui/react';
import Text from 'components/text';
import { useLottie } from 'lottie-react';
import * as animationData from '../../asset/lottie/done.json';
import useQueueScraping from 'hook/useQuueScraping';
import { sleep } from 'utils';
import { createConnection } from 'api/createConnection';
import { ConnectionPlatform } from 'api/type';

interface CollectiveProps {
  onClose: () => void;
  step: number;
  setStep: (step: number) => void;
  selectedProducts: string[];
}

const Collective: React.FC<CollectiveProps> = ({
  onClose,
  step,
  setStep,
  selectedProducts,
}) => {
  const {
    initialized,
    hasOngoing,
    percent,
    trigger,
    waitForOngoingToComplete,
  } = useQueueScraping(ConnectionPlatform.Collective);

  const animationOptions = {
    loop: true,
    animationData: animationData,
  };

  const { View } = useLottie(animationOptions);
  const toast = useToast();

  const [otp, setOtp] = useState('');
  const [showModal, setShowModal] = useState(true);

  const nextId = useRef('');
  const optSent = useRef(false);
  const toastIdRef = useRef<any>();
  const percentRef = useRef(percent);

  const mutateStartConnection = useMutation(
    async (platform: ConnectionPlatform) => {
      const response = await createConnection(platform);

      nextId.current = response.response.nextId;

      return response;
    },
  );

  const onClickSubmit = async () => {
    if (!otp) {
      toast({
        title: 'OTP 번호를 입력하세요.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });

      return null;
    }

    if (!nextId.current) {
      toast({
        title: '연결 정보가 없습니다. 처음부터 다시 시도해주세요.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });

      onClose();
      return null;
    }

    setShowModal(false);

    trigger({
      nextId: nextId.current,
      params: {
        otpCode: otp,
        productIds: selectedProducts,
      },
    });

    toastIdRef.current = toast({
      title: `진행률 0%`,
      description: (
        <Text>상품을 업로드하는 중이에요. 잠시만 기다려주세요!</Text>
      ),
      duration: null,
      status: 'loading',
      isClosable: false,
    });

    const intervalId = setInterval(() => {
      toast.update(toastIdRef.current, {
        title: `진행률 ${Math.round(percentRef.current * 100)}%`,
        description: (
          <Box>
            <Text>상품을 업로드하는 중이에요. 잠시만 기다려주세요!</Text>
          </Box>
        ),
        status: 'loading',
        duration: null,
      });
    }, 1000);

    await sleep(1000);

    try {
      const result = await waitForOngoingToComplete();

      if (result) {
        throw new Error(result.toString());
      }

      clearInterval(intervalId);
      onClose();
      toast.update(toastIdRef.current, {
        description: (
          <Box>
            <Text>업로드에 성공했어요!</Text>
          </Box>
        ),
        status: 'success',
        duration: 3000,
      });
    } catch (error) {
      clearInterval(intervalId);
      onClose();
      toast.update(toastIdRef.current, {
        description: (
          <Box>
            <Text>
              업로드에 실패했어요. OTP 인증번호를 올바르게 입력했는지 확인하고,
              문제가 지속될 경우 고객센터로 문의해주세요.
            </Text>
          </Box>
        ),
        status: 'error',
        duration: 3000,
      });
    }
  };

  const sendOtp = async () => {
    if (hasOngoing) {
      toast({
        description:
          '이미 진행중인 업로드가 있어요. 잠시 후에 다시 시도해주세요!',
        status: 'error',
      });

      onClose();
      return null;
    }

    if (step === 2 && !optSent.current) {
      const result = await mutateStartConnection.mutateAsync(
        ConnectionPlatform.Collective,
      );

      if (result.error) {
        toast({
          description: result.response.message,
          status: 'error',
        });

        onClose();
      }

      optSent.current = true;

      return result;
    }
  };

  useEffect(() => {
    if (step === 2 && !optSent.current && initialized) {
      sendOtp();
    }
  }, [step, initialized]);

  useEffect(() => {
    percentRef.current = percent;
  }, [percent]);

  if (!showModal) {
    return null;
  }

  if (!initialized) {
    return null;
  }

  return (
    <>
      {step === 2 && (
        <>
          <ModalHeader>OTP 인증</ModalHeader>
          <ModalCloseButton onClick={onClose} />
          <ModalBody>
            {mutateStartConnection.isLoading && (
              <div className="flex w-full h-full items-center justify-center">
                <CircularProgress isIndeterminate />
              </div>
            )}

            {!mutateStartConnection.isLoading && (
              <div>
                <Text>휴대폰으로 전송된 OTP 번호를 입력해주세요.</Text>
                <div className="h-4" />
                <Input value={otp} onChange={(e) => setOtp(e.target.value)} />
                <div className="h-4" />
                <div className="flex w-full justify-end">
                  <Button onClick={onClickSubmit}>인증하기</Button>
                </div>
              </div>
            )}
            <div className="h-4" />
          </ModalBody>
        </>
      )}

      {step === 3 && (
        <>
          <ModalHeader>업로드 완료</ModalHeader>
          <ModalCloseButton onClick={onClose} />
          <ModalBody>
            <Text>업로드가 완료되었습니다!</Text>
            <div className="h-4" />
            {View}
          </ModalBody>
        </>
      )}
    </>
  );
};

export default Collective;
