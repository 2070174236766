import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react';
import { Popover } from '@headlessui/react';
import { IdContext } from 'App';
import { useContext, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IoMdList, IoMdMenu, IoMdPerson } from 'react-icons/io';
import Text from 'components/text';
import { signOut } from 'aws-amplify/auth';
import { userState } from 'globalState';
import { useRecoilState } from 'recoil';
import Cognito from 'libs/cognito';

export default function Header({ isLanding }: { isLanding: boolean }) {
  const [cognitoUser, setCognitoUser] = useRecoilState(userState.cognitoUser);

  const checkLogin = async () => {
    if (!cognitoUser) {
      try {
        const cognitoUser = await Cognito.getCurrentUser();
        setCognitoUser(cognitoUser);
      } catch (error) {}
    }
  };

  const logout = async () => {
    await signOut();
    setCognitoUser(null);
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  const navigate = useNavigate();

  useEffect(() => {
    checkLogin();
  }, []);

  return (
    <header className="bg-white flex w-full items-center  justify-center">
      <nav
        className="flex max-w-6xl w-full items-center justify-between p-6 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <span
            className="text-xl font-bold cursor-pointer"
            onClick={() => {
              if (isLanding) {
                navigate('/');
              } else {
                navigate(`/list`);
              }
            }}
          >
            <img
              src={require('../../asset/logo/crossup-dark.png')}
              alt="logo"
              className="h-7"
            />
          </span>
        </div>

        {isLanding && (
          <Popover.Group className="flex lg:gap-x-12">
            <div
              onClick={Cognito.signIn}
              className="text-sm font-semibold leading-6 text-gray-900 cursor-pointer"
            >
              {/* <img
                src={require('../../asset/logo/googleLogin.png')}
                className="h-7"
              /> */}
              로그인
            </div>
          </Popover.Group>
        )}

        {!isLanding && (
          <Popover.Group className="hidden lg:flex lg:gap-x-12">
            <div
              onClick={() => navigate(`/list`)}
              className="text-sm font-semibold leading-6 text-gray-900 cursor-pointer"
            >
              상품 목록
            </div>
            <div
              onClick={() => navigate(`/profile`)}
              className="text-sm font-semibold leading-6 text-gray-900 cursor-pointer"
            >
              프로필 관리
            </div>

            <div
              onClick={logout}
              className="text-sm font-semibold leading-6 text-gray-900 cursor-pointer"
            >
              로그아웃
            </div>
          </Popover.Group>
        )}

        {!isLanding && (
          <div className="lg:hidden">
            <IconButton
              aria-label="Upload"
              icon={<IoMdMenu />}
              onClick={onOpen}
            />

            <Drawer isOpen={isOpen} onClose={onClose} placement="right">
              <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>Menu</DrawerHeader>
                <DrawerBody>
                  <SidebarItem
                    icon={<IoMdList />}
                    label="상품 목록"
                    location={`/list`}
                    open
                    onClick={onClose}
                  />
                  <div className="h-2" />
                  <SidebarItem
                    icon={<IoMdPerson />}
                    label="프로필 관리"
                    location={`/profile`}
                    open
                    onClick={onClose}
                  />
                  <div className="h-2" />
                  <SidebarItem
                    icon={<IoMdPerson />}
                    label="로그아웃"
                    open
                    onClick={logout}
                  />
                </DrawerBody>
              </DrawerContent>
            </Drawer>
          </div>
        )}
      </nav>
    </header>
  );
}

interface SidebarItemProps {
  icon: React.ReactNode;
  label: string;
  location?: string;
  open?: boolean;
  onClick?: () => void;
}

const SidebarItem: React.FC<SidebarItemProps> = ({
  icon,
  label,
  location,
  open,
  onClick,
}) => {
  const currentLocation = useLocation();
  const navigate = useNavigate();

  const isActive = useMemo(() => {
    if (!location) return false;
    return currentLocation.pathname === location;
  }, [currentLocation.pathname, location]);

  return (
    <div
      onClick={() => {
        if (onClick) onClick();
        navigate(location || '/');
      }}
      className={`flex items-center w-full py-3 px-4 rounded-md hover:bg-blue-gray-50 cursor-pointer ${
        !open && 'justify-center'
      } ${isActive && 'bg-blue-gray-50'}`}
    >
      {icon}
      {open && (
        <>
          <div className="w-4" />
          <Text size="md" bold>
            {label}
          </Text>
        </>
      )}
    </div>
  );
};
