import React from 'react';

const PageLayout: React.FC<
  React.HTMLAttributes<HTMLDivElement> & { cssString?: string }
> = ({ children, cssString }) => {
  return (
    <div
      className={`flex h-full w-full justify-center pt-2 px-[20px] ${cssString}`}
    >
      <div className="flex w-full min-w-[300px] max-w-[640px]">{children}</div>
    </div>
  );
};

export default PageLayout;
