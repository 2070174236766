import {
  Button,
  Input,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  InputRightElement,
  Skeleton,
  Stack,
} from '@chakra-ui/react';
import { CheckBadgeIcon, PhoneIcon } from '@heroicons/react/24/solid';
import { createProfile } from 'api/createProfile';
import { deleteProfile } from 'api/deleteProfile';
import { ConnectionPlatform } from 'api/type';
import { IdContext } from 'App';
import Text from 'components/text';
import { useContext, useState } from 'react';
import { useMutation } from 'react-query';

interface CollectiveProps {
  loading: boolean;
  connected: boolean;
  onSubmit: () => void;
}

const Collective: React.FC<CollectiveProps> = ({
  loading,
  connected,
  onSubmit,
}) => {
  const { value } = useContext(IdContext);

  const [toggle, setToggle] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState<string>('');

  const onChangePhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    // check only numbers and less than 11 digits
    const regex = /^[0-9\b]{0,11}$/;
    if (e.target.value === '' || regex.test(e.target.value)) {
      setPhoneNumber(e.target.value);
    }
  };

  const mutateCreateProfile = useMutation(async () => {
    const response = await createProfile({
      serviceKey: value,
      collective: {
        phoneNumber,
      },
    });

    if (!response.error) {
      onSubmit();
      setToggle(false);
      return response.response;
    }
  });

  const mutateDeleteProfile = useMutation(async () => {
    const response = await deleteProfile({
      serviceKey: value,
      platform: ConnectionPlatform.Collective,
    });

    if (!response.error) {
      onSubmit();
      setToggle(false);
      return response.response;
    }
  });

  return (
    <div className="w-full max-w-[350px] py-4 px-6 ring-1 ring-inset ring-gray-300 rounded-md transition-all duration-300">
      <div className="flex items-center justify-between">
        <div className="flex flex-1 items-center">
          <img
            src={require('../../asset/logo/collective.png')}
            alt="collective"
            className="h-7"
          />
          <div className="w-2" />
          <div className="flex flex-col">
            {loading ? (
              <Skeleton width="100px" height="28px" />
            ) : (
              <Text size="md">콜랙티브</Text>
            )}
          </div>
        </div>
        <div className="flex">
          {loading ? (
            <Skeleton width="60px" height="28px" />
          ) : (
            !connected && (
              <Button
                size="sm"
                isLoading={loading}
                isDisabled={loading || connected}
                onClick={() => setToggle(!toggle)}
              >
                {connected ? '연결됨' : '연결하기'}
              </Button>
            )
          )}
          {connected && (
            <>
              <div className="w-2" />
              <Button
                size="sm"
                isLoading={loading}
                isDisabled={loading || !connected}
                variant="outline"
                color="text-stone-600"
                onClick={() => {
                  mutateDeleteProfile.mutate();
                }}
              >
                삭제
              </Button>
            </>
          )}
        </div>
      </div>
      <div
        className={`overflow-hidden transition-all duration-300 ${toggle ? 'max-h-[500px]' : 'max-h-0'}`}
      >
        {toggle && (
          <div className="mt-4 w-full flex flex-col space-y-4">
            <Stack spacing={3}>
              <InputGroup>
                <InputLeftElement>
                  <PhoneIcon width={20} color="gray" />
                </InputLeftElement>
                <Input
                  type="tel"
                  value={phoneNumber}
                  onChange={onChangePhoneNumber}
                  placeholder="전화번호"
                />
              </InputGroup>
            </Stack>

            <div className="flex justify-end">
              <Button
                size="sm"
                isLoading={mutateCreateProfile.isLoading}
                onClick={() => {
                  mutateCreateProfile.mutate();
                }}
              >
                제출
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Collective;
