import {
  getCurrentUser,
  signOut,
  signIn,
  signUp,
  signInWithRedirect,
} from 'aws-amplify/auth';

const Cognito = {
  getCurrentUser: async () => {
    const user = await getCurrentUser();
    return user;
  },
  logout: async () => {
    await signOut();
  },
  signIn: async () => {
    await signInWithRedirect({
      provider: 'Google',
    });
  },
  signUp: async (username: string, email: string, password: string) => {
    try {
      const result = await signUp({
        username,
        password,
        options: {
          userAttributes: {
            email,
          },
        },
      });

      console.log(result);
      return result;
    } catch (error) {
      console.error('Error during sign-up:', error);
      throw error;
    }
  },
};

export default Cognito;
