import { ConnectionPlatform } from './type';
import { apiPost } from 'libs/api';

export const getScrapingStatus = async (platform: ConnectionPlatform) => {
  const result = await apiPost(
    'API',
    '/connections/status',
    {
      platform,
    },
    {
      auth: true,
    },
  );

  return result;
};
