import React, { useState } from 'react';

import Text from 'components/text';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Landing: React.FC = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    // 구글폼의 응답 URL
    const formUrl = `https://docs.google.com/forms/d/e/1FAIpQLSckMGIDoF4cSUonz11KbmuERwoPGPKpc1PJlx2CXIfSXktZIg/formResponse`;

    // 폼 데이터 구성
    const formData = new FormData();
    formData.append('entry.358392689', email);

    try {
      await axios.post(formUrl, formData);
    } catch {
      alert('정상적으로 등록되었습니다');
    }
  };

  return (
    <div className={`flex h-full w-full justify-center pt-2`}>
      <div className="flex w-full min-w-[300px] max-w-full gap-3 flex-col">
        {/* section 1 */}
        <div className="grid grid-cols-1 gap-2 lg:grid-cols-2 lg:grid-cols-2 lg:gap-4 lg:mt-5">
          {/* <!-- Text and Button Container --> */}
          <div className="lg:col-span-1 lg:flex lg:flex-col lg:items-center lg:justify-start lg:mt-5 lg:ml-20">
            <h3 className="text-center font-bold text-3xl lg:pl-2 lg:ml-40 lg:text-left">
              <span className="text-[#88B14B]">한번의 클릭</span>
              으로,
              <br />
              모든 플랫폼에 업로드
            </h3>
            {/* <!-- Button for PC version --> */}
            <div className="lg:ml-60">
              <button
                className="hidden lg:block bg-[#88B14B] hover:bg-[#78A239] text-white font-bold py-2.5 px-4 w-80 rounded lg:mt-10"
                onClick={() =>
                  window.scrollTo({
                    top: document.documentElement.scrollHeight,
                    behavior: 'smooth',
                  })
                }
              >
                지금 체험하러 가기
              </button>
            </div>
          </div>
          {/* <!-- Image Container --> */}
          <div className="lg:col-span-1 lg:ml-20">
            <img
              src={require('../../asset/logo/Hero Image.png')}
              alt="Hero Image"
              className="w-80 h-25 mt-1 mx-auto lg:mb-5 lg:justify-self-start lg:mx-10"
            />
            {/* <!-- Button for Mobile version --> */}
            <button
              className="block lg:hidden bg-[#88B14B] hover:bg-[#78A239] mb-8 text-white font-bold py-2.5 px-4 w-80 rounded mx-auto mt-4"
              onClick={() =>
                window.scrollTo({
                  top: document.documentElement.scrollHeight,
                  behavior: 'smooth',
                })
              }
            >
              지금 체험하러 가기
            </button>
          </div>
        </div>

        {/* section2 */}
        <div className="bg-[#F6FFF2] md:mt-5 py-10">
          <div className="lg:flex lg:ml-10 lg:items-start lg:justify-center lg:gap-20">
            <div className="lg:ml-10 md:mt-10">
              <div className="lg:mt-10 lg:text-left text-center">
                <Text type="h3" bold size="3xl">
                  아직도 하나하나
                  <br /> 일일히 입력하세요?
                </Text>
              </div>
              <div className="my-5 text-[#8B8C8C]">
                <div className="lg:text-left text-center">
                  <Text type="p">
                    하나하나 정보를 입력하지 않고도 여러 플랫폼에 모든
                    <br />
                    정보가 자동으로 입력되어 한번에 업로드 됩니다.
                  </Text>
                </div>
              </div>
            </div>
            <div className="lg:ml-20">
              <div className="grid grid-rows-2 grid-cols-2 gap-2 my-10 md:pb-5 px-5 text-[#363940]">
                <div className="bg-[#FCFCFC] shadow-md p-2">
                  <img
                    src={require('../../asset/logo/Circle Layer.png')}
                    alt="circle Image"
                    className="w-8 h-8 my-1"
                  />
                  <Text type="h3" bold size="lg" textCenter>
                    카테고리 자동 분류
                  </Text>
                  <div className="text-[#8B8C8C]">
                    <Text type="p" size="xs" textCenter>
                      여러 플랫폼에 카테고리가
                      <br />
                      자동으로 알아서 분류됩니다.
                    </Text>
                  </div>
                </div>
                <div className="bg-[#FCFCFC] shadow-md p-2">
                  <img
                    src={require('../../asset/logo/Bag.png')}
                    alt="Hero Image"
                    className="w-8 h-8 my-1"
                  />
                  <Text type="h3" bold size="lg" textCenter>
                    제목, 설명 자동 기입
                  </Text>
                  <div className="text-[#8B8C8C]">
                    <Text type="p" size="xs" textCenter>
                      제목, 설명도 저장된 정보를
                      <br />
                      불러오기 때문에
                      <br />
                      자동으로 기입됩니다.
                    </Text>
                  </div>
                </div>
                <div className="bg-[#FCFCFC] shadow-md p-2">
                  <img
                    src={require('../../asset/logo/User Arrows.png')}
                    alt="Hero Image"
                    className="w-8 h-8 my-1"
                  />
                  <Text type="h3" bold size="lg" textCenter>
                    사이즈, 상태도 알아서
                  </Text>
                  <div className="text-[#8B8C8C]">
                    <Text type="p" size="xs" textCenter>
                      사이즈, 상태도 자동으로
                      <br />
                      기입됩니다.
                    </Text>
                  </div>
                </div>
                <div className="bg-[#FCFCFC] shadow-md p-2">
                  <img
                    src={require('../../asset/logo/Rocket.png')}
                    alt="Hero Image"
                    className="w-8 h-8 my-1"
                  />
                  <Text type="h3" bold size="lg" textCenter>
                    알아서 상품등록까지
                  </Text>
                  <div className="text-[#8B8C8C]">
                    <Text type="p" size="xs" textCenter>
                      여러 플랫폼에 상품등록까지
                      <br />
                      자동으로 업로드 됩니다.
                    </Text>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* section3 */}
          <div className="mt-8 pt-10 md:pt-10">
            {/* PC 버전 */}
            <div className="lg:mt-3 lg:mb-10 lg:block hidden">
              <Text type="h3" textCenter bold size="3xl">
                빈티지 판매에 필요한{' '}
                <span className="text-[#88B14B]">모든 플랫폼 지원</span>
              </Text>
            </div>

            {/* 모바일 버전 */}
            <div className="lg:hidden">
              <Text type="h3" textCenter bold size="3xl">
                빈티지 판매에 필요한 <br />
                <span className="text-[#88B14B]">모든 플랫폼 지원</span>
              </Text>
            </div>
          </div>
          <div className="my-5 mb-8 text-[#8B8C8C]">
            <div className="block lg:hidden text-center">
              <Text type="p">
                인스타그램, 번개장터
                <br />
                후르츠패밀리, 콜렉티브
              </Text>
            </div>
            <div className="hidden lg:block text-center">
              <Text type="p">인스타그램, 번개장터, 후르츠패밀리, 콜렉티브</Text>
            </div>
          </div>
          <div className="mx-auto grid grid-cols-2 grid-rows-2 gap-2 h-[150px] w-[140px] lg:flex lg:justify-center lg:items-center lg:gap-10 lg:h-auto lg:w-auto">
            <div className="flex justify-center items-center">
              <img
                src={require('../../asset/logo/instagramLogo.png')}
                alt="instagram Image"
                className="w-[68px] h-[65px]"
              />
            </div>
            <div className="flex justify-center items-center">
              <img
                src={require('../../asset/logo/bunjangLogo.png')}
                alt="bunjang Image"
                className="w-[68px] h-[65px]"
              />
            </div>
            <div className="flex justify-center items-center">
              <img
                src={require('../../asset/logo/fruitsfamilyLogo.png')}
                alt="fruitsfamily Image"
                className="w-[65px] h-[63px]"
              />
            </div>
            <div className="flex justify-center items-center">
              <img
                src={require('../../asset/logo/collectivLogo.png')}
                alt="collectiv Image"
                className="w-[67px] h-[66px]"
              />
            </div>
          </div>

          {/* section4 */}
          <div className="md:mt-20 mt-8 pt-10">
            {/* PC 버전 */}
            <div className="lg:mt-3 lg:mb-10 lg:block hidden">
              <Text type="h3" textCenter bold size="3xl">
                서비스를 이용하는{' '}
                <span className="text-[#88B14B]">검증된 후기</span>
              </Text>
            </div>

            {/* 모바일 버전 */}
            <div className="lg:hidden">
              <Text type="h3" textCenter bold size="3xl">
                서비스를 이용하는 <br />
                <span className="text-[#88B14B]">검증된 후기</span>
              </Text>
            </div>
          </div>
          <div className="lg:flex lg:justify-center mb-5">
            <div className="">
              <div
                className="h-60 mx-10 md:mb-5 mt-4 bg-cover bg-no-repeat bg-center"
                style={{
                  backgroundImage: `url(${require('../../asset/logo/Card.png')})`,
                }}
              >
                <div className="pt-8">
                  <Text type="p" textCenter bold size="md">
                    너무 편해요!! 이거 꼭 쓰세요!
                  </Text>
                </div>
                <div className="p-5">
                  <Text type="p" textCenter size="sm">
                    개발자님 너무 감사합니다.
                    <br />
                    빈티지 판매하는데 여러개를 여러곳에 올리느라
                    <br />
                    시간을 많이 잡아먹었는데 이 서비스 덕에
                    <br />
                    10분이면 끝나서 좋아요.
                    <br />
                    다들 이거 쓰면 후회안하실거에요
                  </Text>
                </div>
              </div>
              <div className="flex pb-3 justify-center items-center mx-auto md:w-1/2">
                <img
                  src={require('../../asset/logo/buchananLogo.png')}
                  alt="fruitsfamily Image"
                  className="w-10 h-10"
                />
                <div className="pl-3">
                  <Text type="p" bold>
                    뷰캐넌신드롬
                  </Text>
                  <Text type="p" size="xs">
                    서울 종로구
                  </Text>
                </div>
              </div>
            </div>
            <div className="">
              <div
                className="h-60 md:mb-5 mx-10 mt-4 bg-cover bg-center"
                style={{
                  backgroundImage: `url(${require('../../asset/logo/Card.png')})`,
                }}
              >
                <div className="pt-8">
                  <Text type="p" textCenter bold size="md">
                    삶의 질이 달라졌어요
                  </Text>
                </div>
                <div className="p-5">
                  <Text type="p" textCenter size="sm">
                    이런 서비스가 있는걸 진작 알았으면
                    <br />
                    그동안 고생 안했는데 이제 알아버렸네요
                    <br />
                    가격도 저렴하고 5분이면 끝나서 너무 수월해요
                    <br />
                    이젠 그 시간에 다른 일을 할수있어요
                    <br />
                    다른 업체한테도 꼭 이용하라고 소문내고 있어요
                  </Text>
                </div>
              </div>
              <div className="flex justify-center items-center mx-auto md:w-1/2">
                <img
                  src={require('../../asset/logo/apollo.png')}
                  alt="fruitsfamily Image"
                  className="w-10 h-10"
                />
                <div className="pl-3">
                  <Text type="p" bold>
                    아폴로
                  </Text>
                  <Text type="p" size="xs">
                    서울 강남구
                  </Text>
                </div>
              </div>
            </div>
          </div>
          {/* section5 */}
          <div className="pt-20">
            <Text type="h3" textCenter bold size="3xl">
              <span style={{ color: '#88B14B' }}>강력한 보안</span>
              으로 안전하게
            </Text>
          </div>
          <div className="text-[#4F5665] my-10">
            <Text type="p" textCenter>
              최고 수준의 보안으로 안심하세요
            </Text>
          </div>
          <div className="md:pb-10 lg:flex lg:justify-center gap-10">
            <div className="">
              <div
                className="bg-white mt-10 mx-auto w-[200px] h-[200px] shadow-md rounded-full flex items-center justify-center"
                style={{ boxShadow: '0px 2px 15px rgba(0, 0, 0, 0.2)' }}
              >
                <img
                  src={require('../../asset/logo/lock.png')}
                  alt="lock Image"
                  className="w-13 h-13 p-20"
                />
              </div>
              <div className="text-[#4F5665] mt-8 mb-5">
                <Text type="h3" textCenter bold>
                  암호화 통신
                </Text>
              </div>
              <div className="text-[#4F5665]">
                <Text type="p" textCenter>
                  양방향 통신 암호화 및 데이터<br></br>이중 암호화로 당신의
                  정보를
                  <br></br>
                  안전하게 관리합니다
                </Text>
              </div>
              <hr
                className="mt-10 mx-auto lg:hidden"
                style={{ width: '300px' }}
              ></hr>
            </div>
            <div className="lg:ml-4">
              <div
                className="bg-white mt-10 mx-auto w-[200px] h-[200px] shadow-md rounded-full flex items-center justify-center"
                style={{ boxShadow: '0px 2px 15px rgba(0, 0, 0, 0.2)' }}
              >
                <img
                  src={require('../../asset/logo/trash.png')}
                  alt="trash Image"
                  className="w-13 h-13 p-20"
                />
              </div>
              <div className="text-[#4F5665] mt-8 mb-5">
                <Text type="h3" textCenter bold>
                  개인정보 파기
                </Text>
              </div>
              <div className="text-[#4F5665]">
                <Text type="p" textCenter>
                  계정을 삭제할 경우,<br></br>지체없이 모든 개인정보를
                  파기합니다.
                </Text>
              </div>
              <hr
                className="mt-10 mx-auto lg:hidden"
                style={{ width: '300px' }}
              ></hr>
            </div>
            <div className="">
              <div
                className="bg-white mt-10 mx-auto w-[200px] h-[200px] shadow-md rounded-full relative"
                style={{
                  boxShadow: '0px 2px 15px rgba(0, 0, 0, 0.2)',
                  borderRadius: '125px',
                }}
              >
                <img
                  src={require('../../asset/logo/awsLogo.png')}
                  alt="aws Image"
                  className="absolute top-[65px] left-[65px] w-[70px] h-[70px] mx-auto"
                />
              </div>
              <div className="text-[#4F5665] mt-8 mb-5">
                <Text type="h3" textCenter bold>
                  글로벌 표준 보안 준수
                </Text>
              </div>
              <div className="text-[#4F5665]">
                <Text type="p" textCenter>
                  세계 1위 클라우드 서비스AWS로<br></br>세계 최고 수준의 보안을
                  자랑합니다.
                </Text>
              </div>
            </div>
          </div>
        </div>
        {/* section6 */}
        <div className="bg-[#88B14B] md:h-40 md:justify-center md:text-center md:flex">
          <div className="md:ml-10">
            <div className="mx-8 mt-10 text-white md:text-center">
              <Text type="h3" size="2xl" bold>
                메일링 등록하고,
                <br />
                자세한 상담을 받아보세요
              </Text>
            </div>
          </div>
          <div className="md:mx-10 md:mt-3">
            <div className="">
              <div className="mt-8 mx-8">
                <input
                  type="text"
                  placeholder="이메일 / 휴대폰 번호"
                  className="w-48 h-8 pl-2.5 rounded text-xs text-white bg-opacity-20 bg-white placeholder-white"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button
                  onClick={handleSubmit}
                  className="w-16 h-8 bg-white text-[#8A7D7D] text-xs font-bold rounded text-center ml-5"
                >
                  상담신청
                </button>
              </div>
            </div>
            <div className="">
              {/* PC 버전 */}
              <div className="mx-8 mt-3 mb-5 text-white md:block hidden">
                <Text type="p" size="xs">
                  데이터는 안전하게 보관되며,{' '}
                  <span className="">
                    어떠한 경우에도 제3자에게 제공되지 않습니다.
                  </span>
                </Text>
              </div>
              {/* 모바일 버전 */}
              <div className="mx-8 mt-5 mb-5 text-white md:hidden">
                <Text type="p" size="xs">
                  데이터는 안전하게 보관되며,
                  <br />
                  어떠한 경우에도 제3자에게 제공되지 않습니다.
                </Text>
              </div>
            </div>
          </div>
        </div>
        {/* footer */}
        <div className="mx-auto text-[#AEB0B3] mb-5 text-center">
          <Text type="p" size="xs" textCenter>
            © 2024 CrossUp. All rights reserved
          </Text>
          <a href="#" className="text-xs block">
            이용약관
          </a>
          <a href="#" className="text-xs block">
            개인정보처리방침
          </a>
        </div>
      </div>
    </div>
  );
};

export default Landing;
