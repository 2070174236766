import { ConnectionPlatform } from './type';
import { apiPost } from 'libs/api';

export const triggerConnection = async (
  platform: ConnectionPlatform,
  nextId?: string,
  params?: any,
) => {
  let type = 'destination';
  if (platform === ConnectionPlatform.FruiteFamily) {
    type = 'source';
  }

  const result = await apiPost(
    'API',
    '/connections/trigger',
    {
      platform,
      nextId,
      params,
      type,
    },
    {
      auth: true,
    },
  );

  return result;
};
