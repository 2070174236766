import { get, post } from 'aws-amplify/api';

interface ApiResponse {
  error: boolean;
  response: any;
}

interface ApiSettings {
  displayError?: boolean;
  headers?: any;
  auth?: boolean;
}

const apiPost = async (
  apiName: string,
  path: string,
  body: any,
  settings?: ApiSettings,
): Promise<ApiResponse> => {
  let result;
  try {
    const headers = settings?.headers || {};

    if (settings?.auth) {
      path = '/auth' + path;
    } else {
      path = '' + path;
    }

    const request = post({
      apiName,
      path,
      options: {
        body,
        headers: {
          ...headers,
          'Access-Control-Allow-Origin': '*',
        },
      },
    });

    const response = (await request.response).body;
    const bodyJson: ApiResponse =
      (await response.json()) as unknown as ApiResponse;

    return bodyJson;
  } catch (err) {
    console.log(err);
    return {
      error: true,
      response: result,
    };
  }
};

const apiGet = async (
  apiName: string,
  path: string,
  settings?: ApiSettings,
): Promise<ApiResponse> => {
  let result;

  try {
    const headers = settings?.headers || {};

    if (settings?.auth) {
      path = '/auth' + path;
    } else {
      path = '' + path;
    }

    const request = await get({
      apiName,
      path,
      options: {
        headers: {
          ...headers,
          'Access-Control-Allow-Origin': '*',
        },
      },
    });

    const response = (await request.response).body;
    const bodyJson: ApiResponse =
      (await response.json()) as unknown as ApiResponse;

    return bodyJson;
  } catch (err) {
    return {
      error: true,
      response: result,
    };
  }
};

export { apiGet, apiPost };
