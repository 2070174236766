import {
  BunjangConnectionFirstParams,
  InstagramProfile,
  CollectiveConnectionFirstParams,
  FruitFamilySourceConnectionFirstParams,
} from './type';
import { apiPost } from 'libs/api';

export type CreateProfileRequest = {
  serviceKey: string;
  bunjang?: BunjangConnectionFirstParams;
  instagram?: InstagramProfile;
  collective?: CollectiveConnectionFirstParams;
  fruit_family?: FruitFamilySourceConnectionFirstParams;
};

export const createProfile = async (request: CreateProfileRequest) => {
  const result = await apiPost('API', '/connections/create', request, {
    auth: true,
  });

  return result;
};
