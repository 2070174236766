interface TextProps {
  children: React.ReactNode;
  type?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';
  size?: '3xs' | '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl';
  smSize?: '3xs' | '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl';
  color?: string;
  bold?: boolean;
  required?: boolean;
  onClick?: () => any;
  wrap?: boolean;
  textCenter?: boolean;
}

const Text: React.FC<TextProps> = ({
  children,
  type = 'p',
  bold,
  size,
  smSize,
  required,
  color,
  onClick,
  wrap,
  textCenter,
}) => {
  const Tag = type;
  const generateFontSize = () => {
    const fontSize = [];

    if (size && smSize) {
      switch (size) {
        case '3xs':
          fontSize.push('sm:text-[8px]');
          break;
        case '2xs':
          fontSize.push('sm:text-[10px]');
          break;
        case 'xs':
          fontSize.push('sm:text-xs');
          break;
        case 'sm':
          fontSize.push('sm:text-sm');
          break;
        case 'md':
          fontSize.push('sm:text-base');
          break;
        case 'lg':
          fontSize.push('sm:text-lg');
          break;
        case 'xl':
          fontSize.push('sm:text-xl');
          break;
        case '2xl':
          fontSize.push('sm:text-2xl');
          break;
        case '3xl':
          fontSize.push('sm:text-3xl');
          break;
      }

      switch (smSize) {
        case '3xs':
          fontSize.push('text-[8px]');
          break;
        case '2xs':
          fontSize.push('text-[10px]');
          break;
        case 'xs':
          fontSize.push('text-xs');
          break;
        case 'sm':
          fontSize.push('text-sm');
          break;
        case 'md':
          fontSize.push('text-base');
          break;
        case 'lg':
          fontSize.push('text-lg');
          break;
        case 'xl':
          fontSize.push('text-xl');
          break;
        case '2xl':
          fontSize.push('text-2xl');
          break;
        case '3xl':
          fontSize.push('text-3xl');
          break;
      }
    }

    if (size && !smSize) {
      switch (size) {
        case '3xs':
          fontSize.push('text-[8px]');
          break;
        case '2xs':
          fontSize.push('text-[10px]');
          break;
        case 'xs':
          fontSize.push('text-xs');
          break;
        case 'sm':
          fontSize.push('text-sm');
          break;
        case 'md':
          fontSize.push('text-base');
          break;
        case 'lg':
          fontSize.push('text-lg');
          break;
        case 'xl':
          fontSize.push('text-xl');
          break;
        case '2xl':
          fontSize.push('text-2xl');
          break;
        case '3xl':
          fontSize.push('text-3xl');
          break;
      }
    }

    if (fontSize.length != 0) {
      return fontSize.join(' ');
    }

    switch (type) {
      case 'h1':
        return 'text-4xl';
      case 'h2':
        return 'text-3xl';
      case 'h3':
        return 'text-2xl';
      case 'h4':
        return 'text-xl';
      case 'h5':
        return 'text-lg';
      case 'h6':
        return 'text-base';
      case 'p':
        return 'text-base';
      case 'span':
        return 'text-sm';
    }
  };

  const generateOnclickStyle = () => {
    if (onClick) {
      return 'cursor-pointer text-slate-500 hover:text-slate-600';
    }
  };

  return (
    <Tag
      className={`${
        bold ? 'font-bold' : ''
      } ${generateFontSize()} ${generateOnclickStyle()} ${
        !wrap ? 'overflow-clip text-ellipsis whitespace-nowrap' : ''
      } bg-transparent ${color ? color : ''} ${
        textCenter ? 'text-center' : ''
      }`}
      onClick={onClick ? onClick : () => null}
    >
      {children}
      {required && <span className="text-red-600"> *</span>}
    </Tag>
  );
};

export default Text;
