import { ConnectionPlatform } from './type';
import { apiPost } from 'libs/api';

export const createConnection = async (
  platform: ConnectionPlatform,
  nextId?: string,
  params?: any,
) => {
  const result = await apiPost(
    'API',
    '/connections',
    {
      platform,
      nextId,
      type: 'destination',
      params,
    },
    {
      auth: true,
    },
  );

  return result;
};
