import { IdContext } from 'App';
import Text from 'components/text';
import { useContext, useEffect } from 'react';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import FruitsFamily from 'components/connectService/fruitsFamily';
import PageLayout from 'components/layout/page';
import Bunjang from 'components/connectService/bunjang';
import Instagram from 'components/connectService/instagram';
import Collective from 'components/connectService/collective';
import { getConnections } from 'api/getConnection';

const Profile: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { setValue } = useContext(IdContext);

  const queryConnections = useMutation(async () => {
    const response = await getConnections();

    if (!response.error) {
      return response.response;
    }
  });

  const onSubmitProfile = () => {
    queryConnections.mutate();
  };

  useEffect(() => {
    setValue(id || '');
    queryConnections.mutate();
  }, [id]);

  return (
    <PageLayout>
      <div className="flex self-center flex-col w-full h-full p-4 max-w-[800px]">
        <div className="rounded-lg w-full h-full flex flex-col">
          <div className="w-full max-w-[400px]">
            <Text size="xl" bold color="text-blue-600">
              프로필 설정
            </Text>
            <div className="h-2" />

            <Text size="md" wrap>
              CrossUp에서 사용할 서비스 프로필을 관리합니다.
            </Text>
          </div>

          <div className="h-4" />

          <div className="w-full flex flex-col justify-center">
            <div className="flex-1 flex flex-col items-center">
              <Text size="md" bold>
                소스
              </Text>
              <Text size="sm">
                상품 데이터가 수집되는 원본 데이터 소스입니다.
              </Text>

              <div className="h-4" />

              <FruitsFamily
                connected={queryConnections.data?.fruit_family}
                loading={queryConnections.isLoading}
                onSubmit={onSubmitProfile}
              />
            </div>

            <div className="h-4" />

            <div className="flex-1 flex flex-col items-center">
              <Text size="md" bold>
                목적지
              </Text>
              <Text size="sm">수집된 상품 데이터가 업로드되는 대상입니다.</Text>

              <div className="h-4" />

              <Bunjang
                connected={queryConnections.data?.bunjang}
                loading={queryConnections.isLoading}
                onSubmit={onSubmitProfile}
              />

              <div className="h-4" />

              <Instagram
                connected={queryConnections.data?.instagram}
                loading={queryConnections.isLoading}
                onSubmit={onSubmitProfile}
              />

              <div className="h-4" />

              <Collective
                connected={queryConnections.data?.collective}
                loading={queryConnections.isLoading}
                onSubmit={onSubmitProfile}
              />
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default Profile;
