import { getScrapingStatus } from 'api/getScrapingStatus';
import { triggerConnection } from 'api/triggerConnection';
import { ConnectionPlatform } from 'api/type';
import { rejects } from 'assert';
import { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { sleep } from 'utils';

const useQueueScraping = (platform: ConnectionPlatform) => {
  const [hasOngoing, setHasOngoing] = useState(false);
  const [percent, setPercent] = useState(0);
  const [initialized, setInitialized] = useState(false);

  const triggerRefreshFlag = useRef(false);
  const ongoingPromiseResolveRef = useRef<
    ((value: boolean | string) => void) | null
  >(null);

  const queryScrapingStatus = useQuery(
    `scraping_status_${platform}`,
    async () => {
      const response = await getScrapingStatus(platform);

      if (response.response.ongoing) {
        setHasOngoing(true);
      }

      if (!initialized) {
        setInitialized(true);
      }

      return response.response;
    },
    {
      refetchInterval: () => (hasOngoing ? 5000 : false),
    },
  );

  const mutateTriggerConnection = useMutation(
    `mutate_trigger_${platform}`,
    async ({ nextId, params }: { nextId?: string; params?: any }) => {
      const response = await triggerConnection(platform, nextId, params);

      return response.response;
    },
  );

  const trigger = async ({
    nextId,
    params,
  }: {
    nextId?: string;
    params?: any;
  }) => {
    const response = await mutateTriggerConnection.mutateAsync({
      nextId,
      params,
    });

    setHasOngoing(true);

    return response;
  };

  const waitForOngoingToComplete = async (): Promise<boolean | string> => {
    const result = await queryScrapingStatus.refetch();

    if (!result.data.ongoing) {
      return false;
    }

    return new Promise((resolve) => {
      ongoingPromiseResolveRef.current = resolve;
    });
  };

  useEffect(() => {
    console.log(queryScrapingStatus.data);
    if (queryScrapingStatus.data) {
      if (queryScrapingStatus.data.ongoing) {
        setHasOngoing(true);
        setPercent(queryScrapingStatus.data.percent);
        triggerRefreshFlag.current = true;
      } else {
        setHasOngoing(false);
        setPercent(0);

        if (triggerRefreshFlag.current) {
          triggerRefreshFlag.current = false;

          if (ongoingPromiseResolveRef.current) {
            ongoingPromiseResolveRef.current(
              queryScrapingStatus.data.errorMessage,
            );
            ongoingPromiseResolveRef.current = null;
          }
        }
      }
    }
  }, [queryScrapingStatus.data]);

  return {
    initialized,
    hasOngoing,
    percent,
    trigger,
    waitForOngoingToComplete,
  };
};

export default useQueueScraping;
