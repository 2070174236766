import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

export const { persistAtom } = recoilPersist({
  key: 'user-state',
  storage: localStorage,
});

interface User {
  userId: string;
  email: string;
  nickname: string;
}

const user = atom<User>({
  key: 'user',
  default: {
    userId: '',
    email: '',
    nickname: '',
  },
  effects_UNSTABLE: [persistAtom],
});

const cognitoUser = atom<any>({
  key: 'cognitoUser',
  default: null,
  dangerouslyAllowMutability: true,
  effects_UNSTABLE: [persistAtom],
});

export default {
  user,
  cognitoUser,
};
