import { ResourcesConfig } from 'aws-amplify';

export const AmplifyConfig: ResourcesConfig = {
  Auth: {
    Cognito: {
      userPoolId: 'ap-northeast-2_Zuubj1rrX',
      userPoolClientId: '3rb01rc96nl54kovikc1tn5f55',
      identityPoolId: 'ap-northeast-2:caec8d7e-0d13-42e8-b2b0-06b806f4d0d8',
      loginWith: {
        oauth: {
          domain: 'crossup.auth.ap-northeast-2.amazoncognito.com',
          scopes: ['email', 'openid', 'profile'],
          redirectSignOut: [`${window.location.origin}`],
          redirectSignIn: [`${window.location.origin}`],
          responseType: 'token',
          providers: ['Google'],
        },
      },
    },
  },
  API: {
    REST: {
      API: {
        region: 'ap-northeast-2',
        endpoint:
          'https://jm5i56c8cd.execute-api.ap-northeast-2.amazonaws.com/dev',
        // endpoint: 'http://localhost:3000',
      },
    },
  },
};
